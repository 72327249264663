import React, { Component } from 'react';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from '../../../components/runtime-context';
import { getIsLoading, getOpenedModals } from '../store/modal-selectors';
import { withPromisifiedCloseModal } from '../store/modal-actions';
import styles from './modal-root.scss';
import { getIsMobile } from '../../../store/basic-params/basic-params-selectors';

const WithTransition = ({ isEmpty, children }) => (
  <CSSTransitionGroup
    component="div"
    transitionName="modal"
    className={isEmpty ? styles.modalRootCollapsed : styles.modalRoot}
    id="modal-overlay"
    transitionAppear
    transitionEnterTimeout={200}
    transitionAppearTimeout={200}
    transitionLeaveTimeout={200}
  >
    {children}
  </CSSTransitionGroup>
);

const WithoutTransition = ({ isEmpty, children }) => (
  <div className={isEmpty ? styles.modalRootCollapsed : styles.modalRoot} id="modal-overlay">
    {children}
  </div>
);

export const createModalRootComponent = ({ modalComponentMapByType }) => {
  class ModalRoot extends Component {
    state = {
      isEmpty: true,
    };
    static getDerivedStateFromProps = (props, state) => {
      return {
        isEmpty: Object.keys(props.openedModals).length === 0 ? state.isEmpty : false,
      };
    };

    componentWillUnmount() {
      this.props.setRef && this.props.setRef();
    }

    render() {
      const { openedModals, isLoading, closeModal, isMobile, setRef } = this.props;

      const isEmpty = Object.keys(openedModals).length === 0;
      if (isEmpty && !this.setIsEmptyDelayed) {
        this.setIsEmptyDelayed = setTimeout(() => {
          this.setState({ isEmpty: true });
          this.setIsEmptyDelayed = null;
        }, 300);
      }

      // on mobile there is no modal transitions animations as they are choppy anyhow..
      const WrapperComponent = isMobile ? WithoutTransition : WithTransition;

      return (
        <WrapperComponent isEmpty={isMobile ? isEmpty : this.state.isEmpty}>
          {Object.entries(openedModals).map(([type, { props, correlationId }], i) => {
            const closeModalWithResolve = (resolve = false) =>
              closeModal({ type, resolve, correlationId });
            const isModalLoading = isLoading[type];

            const ModalComponent = modalComponentMapByType[type];
            return (
              <div
                ref={setRef ? setRef : noop}
                style={{ zIndex: i, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
                key={type}
              >
                <ModalComponent
                  {...props}
                  type={type}
                  closeModal={closeModalWithResolve}
                  isModalLoading={isModalLoading}
                />
              </div>
            );
          })}
        </WrapperComponent>
      );
    }
  }

  ModalRoot.propTypes = {
    openedModals: PropTypes.object,
    isLoading: PropTypes.bool,
    isMobile: PropTypes.bool,
    closeModal: PropTypes.func,
    blockScroll: PropTypes.func,
    unblockScroll: PropTypes.func,
    setRef: PropTypes.func,
  };

  const mapRuntimeProps = (state, ownProps, actions) => ({
    openedModals: getOpenedModals(state),
    isLoading: getIsLoading(state),
    closeModal: withPromisifiedCloseModal(actions.closeModal),
    isMobile: getIsMobile(state),
  });

  return connect(mapRuntimeProps)(ModalRoot);
};
