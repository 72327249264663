import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';
import Link from '../link/internal-link';
import {
  getCategoryBySlug,
  getAllCategories,
} from '../../../common/selectors/categories-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { isSeo } from '../../../common/store/basic-params/basic-params-selectors';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withMainPageEnabled from '../../hoc/with-main-page-enabled';
import { ChevronUpDownIcon } from '../icons/chevron-up-down-icon';
import styles from './categories-select.scss';

const CategoriesSelect = ({
  categories,
  currentCategory,
  onChange,
  t,
  isMainPageEnabled,
  isSeo,
}) => {
  const className = classNames(
    styles.container,
    'forum-text-color',
    'categories-button',
  );

  const allPostsCategory = {
    label: t('categories-link.all-posts'),
    slug: '',
    isIndented: false,
  };
  const categoryOptions = categories.map(category => ({
    label: category.label,
    slug: category.slug,
    isIndented: !!category.parentId,
  }));
  const options = [...(!isMainPageEnabled ? [allPostsCategory] : []), ...categoryOptions];

  const current = currentCategory || (!isMainPageEnabled ? allPostsCategory : currentCategory);
  const singleCategory = categories.length === 1;

  return (
    <div className={className}>
      <div className={styles.optionsContainer}>
        {!singleCategory && !isSeo && (
          <div className={styles.selectContainer}>
            <span className={styles.categoryTitle}>{current && current.label}</span>
            <ChevronUpDownIcon className="forum-icon-fill" />
            <select
              className={styles.select}
              onChange={e => onChange(e.target.value)}
              value={current && current.slug}
              data-hook="category-select"
            >
              {options.map(category =>
                category.isIndented ? (
                  <option
                    key={category.slug}
                    value={category.slug}
                    data-hook={`category-select-action__${category.slug}`}
                  >
                    {category.label}
                  </option>
                ) : (
                  <option
                    key={category.slug}
                    value={category.slug}
                    data-hook={`category-select-action__${category.slug}`}
                  >
                    {category.label}
                  </option>
                ),
              )}
            </select>
          </div>
        )}
        {!singleCategory && isSeo && (
          <div className={styles.selectContainer}>
            {options.map(category => (
              <Link to={`/${category.slug}`}>{category.label}</Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

CategoriesSelect.propTypes = {
  categories: PropTypes.array,
  currentCategory: PropTypes.object,
  onChange: PropTypes.func,
  t: PropTypes.func,
  isMainPageEnabled: PropTypes.bool,
  isSeo: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, { navigateWithinForum }) => {
  const { categorySlug } = getRouteParams(state);
  return {
    categories: getAllCategories(state),
    currentCategory: getCategoryBySlug(state, categorySlug),
    onChange: slug => (slug ? navigateWithinForum(`/${slug}`) : navigateWithinForum(`/`)),
    isSeo: isSeo(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withMainPageEnabled,
)(CategoriesSelect);
