import { AvatarGroup as WUTAvatarGroup } from 'wix-ui-tpa/AvatarGroup';
import React from 'react';
import classNames from 'classnames';
import stStyles from './avatar-group.st.css';
import styles from './avatar-group.scss';

const AvatarGroup = ({
  items = [],
  totalItemCount,
  onClick,
  limit = 3,
  isMobile,
  dataHook,
  className,
}) => (
  <div className={classNames(styles.container, className)} onClick={onClick} data-hook={dataHook}>
    <WUTAvatarGroup
      {...stStyles('root')}
      size="xSmall"
      items={items.slice(0, limit).map(item => ({ name: item.name, src: item.image }))}
      maxAmount={limit}
    >
      {totalItemCount - limit > 0 && (
        <WUTAvatarGroup.TextButton
          style={{
            ...(isMobile && { fontSize: '12px' }),
            opacity: '0.8',
            marginLeft: '-2px',
            whiteSpace: 'nowrap',
          }}
        >
          {totalItemCount}
        </WUTAvatarGroup.TextButton>
      )}
    </WUTAvatarGroup>
  </div>
);

export default AvatarGroup;
