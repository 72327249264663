import getMainTheme from './theme';
import styles from './theme-post-form.scss';
import { mergeStyles } from './utils';

export default function getTheme(args) {
  return mergeStyles(getMainTheme(args), {
    footerToolbar: styles.footerToolbar,
    footerToolbar_buttons: styles.footerToolbar_buttons,
  });
}
