import { flowRight, isString, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import getImagesData from 'wix-rich-content-fullscreen/dist/lib/getImagesData.cjs';
import { loadable } from '@wix/communities-forum-client-commons/dist/src/services/loadable';
import {
  RichContentViewer as Viewer,
  linkTypeMapper,
  imageTypeMapper,
  videoTypeMapper,
  dividerTypeMapper,
  htmlTypeMapper,
  giphyTypeMapper,
  HTML_TYPE,
  IMAGE_TYPE,
  VIDEO_TYPE,
  HashtagDecorator,
  MENTION_TYPE,
  mentionsTypeMapper,
  CodeBlockDecorator,
  textColorInlineStyleMapper,
  fileUploadTypeMapper,
  linkPreviewTypeMapper,
  LINK_PREVIEW_TYPE,
  FILE_UPLOAD_TYPE,
  TEXT_COLOR_TYPE,
} from '../rce/rce-viewer';

import withDeviceType from '../../hoc/with-device-type';
import { getIsSocialSharingLinksEnabled } from '../../selectors/app-settings-selectors';
import getOuterUrl from '../../services/get-outer-url';
import { getLocation } from '../../../common/store/location/location-selectors';
import {
  isSeo,
  isEditor,
  getLanguage,
  isSSR,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import getImageUrl from '@wix/communities-forum-client-commons/dist/src/services/get-image-url';
import theme from '../rich-content-editor/theme';
import styles from './rich-content-viewer.scss';
import {
  createHashtagHref,
  createHashtagPath,
  MAX_PINTEREST_IMAGE_SIZE,
  HTML_IFRAME_SRC,
} from '../rich-content-editor/utils';
import withFontClassName from '../../hoc/with-font-class-name';
import { isMemberAreaInstalled } from '../../../common/store/communities-context/communities-context-selectors';
import {
  viewerCustomStyleFn,
  styleSelectionPredicate,
  getTextColorSchema,
} from '../rich-content-editor/text-color-utils';
import { RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY } from '../messages/message-types';
import { isIos } from '../../../common/services/detect-platform';

const relValue = 'noreferrer';
const anchorTarget = '_blank';

const Fullscreen = loadable(() =>
  import(
    /* webpackChunkName: "forum-rcv-fullscreen" */ 'wix-rich-content-fullscreen/dist/module.cjs'
  ),
);

class RichContentViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expendModeIsOpen: false,
      expandModeIndex: undefined,
    };
    this.initViewerProps();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.isEditor ||
      nextProps.pageUrl !== this.props.pageUrl ||
      nextProps.initialRawState !== this.props.initialRawState ||
      nextProps.isSSR !== this.props.isSSR ||
      this.state !== nextState
    );
  }

  componentDidUpdate() {
    if (this.props.isEditor) {
      this.initTheme();
    }
  }

  getImagePluginConfig() {
    const { isSocialSharingLinksEnabled } = this.props;
    const showPin = this.props.readOnly && isSocialSharingLinksEnabled;
    return {
      imageProps: showPin
        ? src => ({
            'data-pin-url': this.props.pageUrl,
            'data-pin-media': isString(src)
              ? src
              : getImageUrl(src, MAX_PINTEREST_IMAGE_SIZE, MAX_PINTEREST_IMAGE_SIZE),
          })
        : { 'data-pin-nopin': true },
    };
  }

  getVideoPluginConfig() {
    return {
      // Function is invoked when rendering video which has relative URL.
      // You should take the pathname and form a full URL.
      getVideoUrl: src => `https://video.wixstatic.com/${src.pathname}`,
    };
  }

  onHashTagClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const pathname = createHashtagPath(event.target.innerText);
    this.props.navigateWithinForum(pathname);
  };

  initViewerProps() {
    const {
      isMembersAreaInstalled,
      navigateToProfile,
      requestFileDownloadUrlPromisified,
      fileDownloadRequest,
      showMessage,
      style,
      origin,
      isEditor,
      isMobile,
      isPreview,
      buttonClicked,
    } = this.props;

    this.typeMappers = [
      videoTypeMapper,
      imageTypeMapper,
      dividerTypeMapper,
      htmlTypeMapper,
      linkTypeMapper,
      mentionsTypeMapper,
      giphyTypeMapper,
      fileUploadTypeMapper,
      linkPreviewTypeMapper,
    ];

    this.helpers = {};
    if (!(isEditor || isPreview || isMobile)) {
      this.helpers.onExpand = (entityIndex, innerIndex = 0) => {
        buttonClicked({ name: 'img_expand', origin });
        //galleries have an innerIndex (i.e. second image will have innerIndex=1)
        this.setState({
          expendModeIsOpen: true,
          expandModeIndex: this.expandModeData.imageMap[entityIndex] + innerIndex,
        });
      };
    }

    this.initTheme();

    const colorScheme = getTextColorSchema(style);

    this.config = {
      [LINK_PREVIEW_TYPE]: {
        enableEmbed: true,
      },
      [FILE_UPLOAD_TYPE]: {
        resolveFileUrl: imgEntityData => {
          isIos() && showMessage(RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY);
          const getBiEventData = isSuccessful => ({
            fileId: imgEntityData.id,
            fileExtension: imgEntityData.type,
            mimeType: imgEntityData.mimeType,
            size: imgEntityData.size,
            isSuccessful,
            origin,
          });

          return requestFileDownloadUrlPromisified(imgEntityData.path)
            .then(data => {
              fileDownloadRequest(getBiEventData(true));
              return data.downloadUrl;
            })
            .catch(() => fileDownloadRequest(getBiEventData(false)));
        },
      },
      [TEXT_COLOR_TYPE]: {
        customStyleFn: viewerCustomStyleFn(colorScheme),
        styleSelectionPredicate: styleSelectionPredicate(colorScheme),
      },
      [HTML_TYPE]: {
        htmlIframeSrc: HTML_IFRAME_SRC,
      },
      [IMAGE_TYPE]: this.getImagePluginConfig(),
      [VIDEO_TYPE]: this.getVideoPluginConfig(),
      [MENTION_TYPE]: {
        onMentionClick: isMembersAreaInstalled ? mention => navigateToProfile(mention.slug) : null,
        getMentionLink: noop,
      },
    };

    this.initDecorators();
    this.initInlineStyleMappers(this.config);
  }

  initInlineStyleMappers(config) {
    this.inlineStyleMappers = [textColorInlineStyleMapper(config, this.props.initialRawState)];
  }

  getExpandedModeImagesData = () => {
    if (!this.expandModeData) {
      this.expandModeData = getImagesData(this.props.initialRawState);
    }

    return this.expandModeData.images;
  };

  initTheme() {
    const {
      style,
      titleFontClassName,
      contentFontClassName,
      compact,
      isSeo,
      themeGetter,
      type,
    } = this.props;

    this.theme = themeGetter({
      style,
      readOnly: true,
      compact,
      isViewer: true,
      isSeo,
      titleFontClassName,
      contentFontClassName,
      type,
    });
  }

  initDecorators() {
    const { sectionUrl } = this.props;

    this.decorators = [
      new HashtagDecorator({
        theme: this.theme,
        onClick: this.onHashTagClick,
        createHref: createHashtagHref(sectionUrl),
      }),
      new CodeBlockDecorator({ theme: this.theme }),
    ];
  }

  render() {
    const { initialRawState, isMobile, isSSR } = this.props;
    const { expendModeIsOpen, expandModeIndex } = this.state;

    return (
      <div className={styles.container}>
        <Viewer
          typeMappers={this.typeMappers}
          ref={ref => (this.editor = ref)}
          {...this.props}
          initialState={initialRawState}
          helpers={this.helpers}
          decorators={this.decorators}
          theme={this.theme}
          platform={isMobile ? 'mobile' : 'desktop'}
          anchorTarget={anchorTarget}
          relValue={relValue}
          config={this.config}
          inlineStyleMappers={this.inlineStyleMappers}
        />
        {!isSSR && (
          <Fullscreen
            isOpen={expendModeIsOpen}
            images={this.getExpandedModeImagesData()}
            onClose={() => this.setState({ expendModeIsOpen: false })}
            index={expandModeIndex}
          />
        )}
      </div>
    );
  }
}

RichContentViewer.propTypes = {
  initialRawState: PropTypes.object,
  compact: PropTypes.bool,
  style: PropTypes.object.isRequired,
  navigateWithinForum: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  titleFontClassName: PropTypes.string,
  contentFontClassName: PropTypes.string,
  isSeo: PropTypes.bool,
  themeGetter: PropTypes.func,
  navigateToProfile: PropTypes.func,
  isMembersAreaInstalled: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSocialSharingLinksEnabled: PropTypes.bool,
  sectionUrl: PropTypes.string,
  origin: PropTypes.string,
  requestFileDownloadUrlPromisified: PropTypes.func,
  showMessage: PropTypes.func,
  fileDownloadRequest: PropTypes.func,
  type: PropTypes.string,
  locale: PropTypes.string,
  isSSR: PropTypes.bool,
  isPreview: PropTypes.bool,
  buttonClicked: PropTypes.func,
};

RichContentViewer.defaultProps = {
  compact: false,
  themeGetter: theme,
};

RichContentViewer.POST_PAGE = 'postPage';

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const location = getLocation(state);
  const sectionUrl = location.sectionUrl;
  return {
    style: host.style,
    pageUrl: getOuterUrl(location.pathname, sectionUrl),
    isSeo: isSeo(state),
    isEditor: isEditor(state),
    isPreview: isPreview(state),
    isMembersAreaInstalled: isMemberAreaInstalled(state),
    isSocialSharingLinksEnabled: getIsSocialSharingLinksEnabled(state, host.style),
    sectionUrl,
    navigateWithinForum: actions.navigateWithinForum,
    navigateToProfile: actions.navigateToProfile,
    requestFileDownloadUrlPromisified: actions.requestFileDownloadUrlPromisified,
    showMessage: actions.showMessage,
    fileDownloadRequest: actions.fileDownloadRequest,
    locale: getLanguage(state),
    isSSR: isSSR(state),
    buttonClicked: actions.buttonClicked,
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withFontClassName,
)(RichContentViewer);
